<template>
    <div class="qa-index">
        <md-tabs @change="tabChange" ref="tabs">
            <md-tab :md-active="tabIndex==0" md-label="My Ticket List">
                <q-a-list :my="true" v-if="tabIndex==0"></q-a-list>
            </md-tab>
            <md-tab :md-active="tabIndex==1" :md-disabled="page!='detail'" md-label="Ticket Detail">
                <q-a-detail :id="id" v-if="tabIndex==1"></q-a-detail>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
// see OrderIndex.vue
import QAList from "@/components/QA/QAList.vue";
import QADetail from "@/components/QA/QADetail";
import QAEdit from "@/components/QA/QAEdit";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "qaIndex",
    props: {
        page: {
            type: String,
            default: "my-list"
        },
        id: {
            type: String,
            default: ""
        }
    },
    components: {
        QAList,
        QADetail,
        QAEdit
    },
    data() {
        return {
            tabIndex: 0,
            ready: false
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user"
            //hasPermission: "user/hasPermission"
        })
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                switch (this.page) {
                    case "my-list":
                        this.tabIndex = 0;
                        break;
                    case "detail":
                        this.tabIndex = 1;
                        break;
                }

                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },
    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }
            if (index == 0) {
                this.$router.push("/admin/qas/my");
            }
            if (index == 1) {
                this.$router.push(`/admin/qas/my/detail/${this.id}`);
            }
        }
    },
    created() {
        // let that = this; //should use that,or 'this' will point to Vue but not current vue component
    }
};
</script>

